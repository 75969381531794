<template>
  <div class="page-container">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
      <img class="zg--header--logo" src="https://image.qll-times.com/2020/05/d3c842b2adb3438ea13ff55c43689201.jpg">
      <label class="zg--header--title">商机圈子平台</label>
      <div class="zg--header--button">
        <el-button type="primary" @click="updateBrochure">保存</el-button>
      </div>        
    </el-header>

    <!-- 主区域 -->
    <div class="left">
      <div class="bg-img-cover-center brochure-container" :style="{
        backgroundImage: `url(${configs.bg.type == 1 ? configs.bg.defaultUrl : configs.bg.customUrl})`
      }">
        <p v-if="configs.children.length < 1" class="tips-none">右侧添加页面</p>
        <el-carousel v-else ref="brochurePreview" height="603px" indicator-position="none" :interval="0"
          @change="selectCurrentPage">
          <el-carousel-item v-for="item in configs.children" :key="item.id">
            <div class="brochure-item">
              <img :src="item.image" :style="{
                transform: `translate(-50%, -50%) rotate(${item.rotate || 0}deg) scale(${item.scale || 1})`
              }">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="right">
      <div class="brochure-form">
        <!-- 手册名称 -->
        <div class="form-item">
          <div class="item-label">手册名称</div>
          <div class="item-content">
            <el-input v-model="configs.title" maxlength="30" show-word-limit placeholder="请输入手册名称"></el-input>
          </div>
        </div>

        <!-- 手册简介 -->
        <div class="form-item">
          <div class="item-label">手册简介</div>
          <div class="item-content">
            <el-input v-model="configs.desc" maxlength="30" show-word-limit placeholder="请输入手册简介"></el-input>
          </div>
        </div>

        <!-- 手册背景 -->
        <div class="form-item">
          <div class="item-label">手册背景</div>
          <div class="item-content">
            <!-- 手册背景选择条件 -->
            <div class="item-radio">
              <el-radio v-model="configs.bg.type" :label="1">使用默认背景</el-radio>
              <el-radio v-model="configs.bg.type" :label="2">自定义背景</el-radio>
            </div>

            <!-- 手册默认背景 -->
            <div v-show="configs.bg.type == 1" class="item-brochure-bg">
              <div class="list" v-for="item in defaultBgList" :key="item.id"
                @click="selectBrochureBg(item)"
                :class="{'list-active': item.id == configs.bg.selected}">
                <img :src="item.url">
              </div>
            </div>

            <!-- 手册自定义背景 -->
            <div v-show="configs.bg.type == 2" class="item-brochure-bg">
              <div class="item-brochure-bg">
                <div class="upload-bg" @click="crooperBrochureBg">
                  <div v-if="configs.bg.customUrl" class="list"><img :src="configs.bg.customUrl"></div>
                  <template  v-else>
                    <p class="add">+</p><p>立即上传</p>
                  </template>
                </div>
              </div>
            </div>
            <p class="text-tips">建议上传图片大小为 670 x 1006像素</p>
          </div>
        </div>

        <!-- 手册封面 -->
        <div class="form-item">
          <div class="item-label">手册封面</div>
          <div class="item-content">
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadLogo">
              <div class="brochure-logo">
                <img v-if="configs.logo" :src="configs.logo">
                <template  v-else>
                  <p class="add">+</p><p>立即上传</p>
                </template>
              </div>
            </file-upload>
          </div>
        </div>

        <!-- 手册内容 -->
        <div class="form-item" style="border-bottom: 1px solid #ddd;">
          <div class="item-label">手册内容</div>
          <div class="item-content">
            <p class="text-tips">手册所有页面建议统一尺寸：600*900，最多可添加100张图片，拖动可调整图片顺序</p>
          </div>
        </div>
      </div>

      <!-- 手册页面列表 -->
      <draggable :list="configs.children">
        <transition-group>
          <div class="borchure-page" v-for="(item, index) in configs.children" :key="item.id"
            @click.stop="previewSelectedPage(item, index)"
            :class="{'borchure-page-active': currentPageId == item.id}">
            <!-- 页面上传图片 -->
            <div class="logo">
              <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadPageImage">
                <img v-if="item.image" :src="item.image">
                <template v-else><p class="add">+</p><p>立即上传</p></template>
              </file-upload>
            </div>
            
            <!-- 页面名称 -->
            <div class="item">
              <label>页面名称：</label>
              <el-input class="inline-input" 
                v-model="item.title" maxlength="8" show-word-limit placeholder="请输入页面名称"></el-input>
            </div>

            <!-- 跳转页面 -->
            <div class="item">
              <label>跳转页面：&nbsp;{{item.redirectConfigs.title}}&nbsp;&nbsp;&nbsp; 
                <el-dropdown>
                  <i class="zgcms update">修改&nbsp;&#xe601;</i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="updatePageRedirectType(item, 2)">问卷</el-dropdown-item>
                    <el-dropdown-item @click.native="updatePageRedirectType(item, 1)">商品</el-dropdown-item>
                    <el-dropdown-item @click.native="updatePageRedirectType(item, 0)">不跳转</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </label>
            </div>

            <!-- 按钮文案 -->
            <div class="item" v-if="item.redirectConfigs.type != 0">
              <label>按钮文案：</label>
              <el-input class="inline-input" 
                v-model="item.buttonText" maxlength="8" show-word-limit placeholder="请填写按钮文案"></el-input>
            </div>

            <!-- 图片操作 -->
            <div class="item">
              <label>旋转图片：</label>
              <el-button class="el-icon-refresh-left" plain @click="trasnformPageImage(item, 'rotate-')"></el-button>
              <el-button class="el-icon-refresh-right" plain @click="trasnformPageImage(item, 'rotate+')"></el-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label>缩放图片：</label>
              <el-button class="el-icon-minus" plain @click="trasnformPageImage(item, 'scale-')"></el-button>
              <el-button class="el-icon-plus" plain @click="trasnformPageImage(item, 'scale+')"></el-button>
            </div>

            <!-- 关闭按钮 -->
            <span @click.stop="delPage(item)" class="zgcms close">&#xe654;</span>
          </div>
        </transition-group>
      </draggable>
      
      <!-- 添加页面按钮 -->
      <div class="borchure-page add-borchure-pag" @click="addNewPage">
        <el-button type="primary" class="el-icon-plus add-newpage">&nbsp;添加页面</el-button>
        <p class="tips">手册所有页面建议统一尺寸：600*900</p>
      </div>
    </div>
  </div>
</template>

<script>
import uoloadImage from "@/websites/cms/js/dialog"
import upload from "@/websites/cms/components/FileUpload"
import { RandomCode } from "@/js/Utils"
import draggable from "vuedraggable"
export default {
  components: { draggable },
  data(){
    return {
      defaultBgList: [ // cms系统默认宣传册背景
        {id: 0, url: 'https://img0.qll-times.com/cdn/zgimg/brochure-bg-1.jpeg'},
        {id: 1, url: 'https://img0.qll-times.com/cdn/zgimg/brochure-bg-2.jpg'},
        {id: 2, url: 'https://img0.qll-times.com/cdn/zgimg/brochure-bg-3.jpg'}
      ],
      configs: { // 宣传册配置
        title: "", // 宣传册标题
        logo: "", // 宣传册logo
        desc: "", // 宣传册详情
        pageNum: 0, // 宣传册页数
        goodsNum: [], // 宣传册商品数量
        width: 0,
        height: 0,
        bg: { type: 1, selected: 0, defaultUrl: "", customUrl: "" },
        children: []
      },
      currentPageId: "", // 当前选中的页面ID
    }
  },
  created(){
    if(this.$route.query.sid) this.getBrochure();
  },
  methods: {
    // 创建/更新宣传册
    async updateBrochure(){
      if(!this.checkBrochureConfigs()) return;
      let res;

      // 更新宣传册总页数以及设定宣传册的尺寸，挑选最大的尺寸，并按比例将宽缩小到350
      this.configs.pageNum = this.configs.children.length;
      let maxWidth = 0, maxHeight = 0, maxIndex = 0;
      this.configs.children.map((e, index) => {
        if(maxWidth < e.width){
          maxWidth = e.width;
          maxHeight = e.height;
          maxIndex = index;
        }
      });
      if(maxWidth > 350){
        this.configs.width = 350;
        this.configs.height = maxHeight * 350 / maxWidth;
      }else{
        this.configs.width = maxWidth;
        this.configs.height = maxHeight;
      }
      if(this.$route.query.sid){
        let data = {
          title: this.configs.title,
          configs: JSON.stringify(this.configs)
        }
        res = await this.$ZGManager.updateMaterial(this.$route.query.sid, data);
        if(res.status != 200) return;
        this.$message.success("修改成功");
      }else{
        let params = {
          type: 8,
          title: this.configs.title,
          configs: JSON.stringify(this.configs)
        }
        res = await this.$ZGManager.addMaterial(params);
        if(res.status != 200) return;
        this.$router.replace({ query: { sid: res.data.id } });
        this.$message.success("创建成功");
      }
    },

    // 查询宣传册配置
    async getBrochure(){
      let res = await this.$ZGManager.getMaterial(this.$route.query.sid);
      if(res.status != 200) return;
      this.configs = JSON.parse(res.data.configs);
    },

    // 删除页面
    async delPage(item){
      let dialog = await this.$confirm(`是否要删除“${item.title}”？删除后不可恢复！`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      });
      if(dialog != 'confirm') return;
      let index = this.configs.children.findIndex(e => e.id == item.id);
      this.configs.children.splice(index, 1);
      if(this.configs.children.length < 1){
        this.currentPageId = "";
        this.configs.bg.defaultUrl = "";
      }
    },

    // 保存宣传册前的校验
    checkBrochureConfigs(){
      if(this.configs.title.length < 2){
        this.$message.error("手册名称至少2个字");
        return false;
      }
      if(!this.configs.desc){
        this.$message.error("请填写手册简介");
        return false;
      } 
      if(!this.configs.logo){
        this.$message.error("请上传封面图片");
        return false; 
      }
      if(this.configs.children.length < 1){
        this.$message.error("至少添加一张页面");
        return false;
      }
      let temp = true;
      for(let index in this.configs.children){
        if(!this.configs.children[index].image){
          this.currentPageId = this.configs.children[index].id;
          this.$message.error("请上传页面图片");
          temp = false;
          break;
        }
      }
      return temp;
    },

    // 裁剪手册背景
    crooperBrochureBg(){
      uoloadImage.show({
        type: 'crooperImage',
        cropWidth: 670,
        cropHeight: 1006,
        fixedArray: [670, 1006],
        isfixedBox: true,
        selected: (item) => {
          this.selectBrochureBg(item);
        }
      })
    },

    // 选择手册背景 1默认背景 2自定义背景
    selectBrochureBg(item){
      if(this.configs.bg.type == 1){
        this.configs.bg.selected = item.id;
        this.configs.bg.defaultUrl = item.url;
      }else if(this.configs.bg.type == 2){
        this.configs.bg.customUrl = item.url;
      }
    },

    // 上传封面图
    uploadLogo(data){
      this.configs.logo = data.url;
    },

    // 添加新页面
    addNewPage(){
      this.configs.children.push({
        id: RandomCode(), 
        title: `页面${this.configs.children.length + 1}`, 
        buttonText: "",
        image: "",
        width: 0,
        height: 0,
        redirectConfigs: {
          type: 0,
          title: "不跳转",
          url: ""
        },
        rotate: 0
      });
      if(this.configs.children.length == 1){
        this.currentPageId = this.configs.children[0].id;
        this.configs.bg.defaultUrl = this.defaultBgList[this.configs.bg.selected].url;
      }
    },

    // 上传页面图片
    uploadPageImage(data){
      let index = this.configs.children.findIndex(e => e.id == this.currentPageId);
      if(index == -1) return;
      let item = this.configs.children[index];
      item.image = data.url;
      this.configs.children.splice(index, 1, item);

      // 读取图片，获取图片的
      let img = new Image();
      img.src = item.image;
      img.onload = () => { 
        item.width = img.width;
        item.height = img.height;
      };
    },

    // 修改页面跳转类型
    updatePageRedirectType(item, type){
      let index = this.configs.children.findIndex(e => e.id == item.id);
      if(index == -1) return;
      let data = this.configs.children[index];
      data.redirectConfigs.type = type;
      if(type == 0) data.redirectConfigs.title = "不跳转";
      if(type == 1) data.redirectConfigs.title = "商品";
      if(type == 2) data.redirectConfigs.title = "问卷";
      this.configs.children.splice(index, 1, data);
    },

    // 选中页面，预览框跳转到相应的预览页面
    previewSelectedPage(item, index){
      this.currentPageId = item.id;
      this.$refs.brochurePreview.setActiveItem(index);
    },

    // 预览框切换页面，右侧配置栏跟着选中当前页面
    selectCurrentPage(index){
      this.currentPageId = this.configs.children[index].id;
    },

    // 旋转、放大页面的图片
    trasnformPageImage(item, type){
      if(!item.image) return;
      if(!item.rotate) item.rotate = 0;
      if(!item.scale) item.scale = 1;
      switch(type){
        case "rotate-": item.rotate -= 90;
          break;
        case "rotate+": item.rotate += 90;
          break;
        case "scale-": item.scale -= 0.1;
          break;
        case "scale+": item.scale += 0.1;
          break;
        default: break;
      }
      let index = this.configs.children.findIndex(e => e.id == item.id);
      if(index >= 0) this.configs.children.splice(index, 1, item);
    }
  },
  components:{
    'file-upload': upload
  }
}
</script>

<style lang="scss" scoped>
.page-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.left{
  width: calc(100% - 900px);
  height: calc(100% - 64px);
  overflow: hidden;
  float: left;
  position: relative;
  .brochure-container{
    width: 375px;
    height: 603px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    .tips-none{
      line-height: 603px;
      text-align: center;
      font-size: 16px;
      color: #666;
    }
    .brochure-item{
      width: 100%;
      height: 100%;
      position: relative;
      img{
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}
.right{
  width: calc(900px - 60px);
  height: calc(100% - 114px);
  padding: 30px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  .brochure-form{
    width: 100%;
    .form-item{
      margin-bottom: 20px;
      .item-label{
        width: 80px;
        height: 40px;
        line-height: 40px;
        color: #666666;
      }
      .item-content{
        width: calc(100% - 80px);
        margin-top: -40px;
        margin-left: 80px;
        .item-radio{
          line-height: 40px;
        }
        .text-tips{
          line-height: 40px;
          font-size: 12px;
          color: #bbb;
        }
        .item-brochure-bg{
          .list{
            width: 100px;
            height: 138px;
            margin-right: 10px;
            border: 2px solid #fff;
            box-sizing: border-box;
            display: inline-block;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .list-active{
            border-color: #2d7ee8;
          }
          .upload-bg{
            width: 100px;
            height: 138px;
            border: 1px dashed #bbb;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: #f5f5f5;
            text-align: center;
            cursor: pointer;
            .list{
              border: none;
            }
            .add{
              font-size: 50px;
              font-weight: 100;
              margin-top: 10px;
            }
          }
        }
        .brochure-logo{
          width: 130px;
          height: 130px;
          border: 1px dashed #bbb;
          border-radius: 5px;
          box-sizing: border-box;
          background-color: #f5f5f5;
          text-align: center;
          position: relative;
          overflow: hidden;
          .add{
            font-size: 50px;
            font-weight: 100;
            margin-top: 10px;
          }
          img{
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .borchure-page{
    width: calc(100% - 44px);
    height: calc(130px - 4px);
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #f5f5f5;
    background-color: #f5f5f5;
    position: relative;
    .close{
      padding: 2px;
      border-radius: 50%;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
    }
    .logo{
      width: 130px;
      height: 130px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: #fafafa;
      text-align: center;
      position: relative;
      float: left;
      .add{
        font-size: 50px;
        font-weight: 100;
        margin-top: 10px;
      }
      img{
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .item{
      height: 43px;
      line-height: 43px;
      padding-left: 150px;
      color: #666;
      .inline-input{
        width: 240px;
        display: inline-block;
      }
      .update{
        font-size: 14px;
        color: #2d7ee8;
        cursor: pointer;
      }
    }
  }
  .borchure-page-active{
    border-color: #2d7ee8;
  }
  .add-borchure-pag{
    height: 60px;
    text-align: center;
    p{
      line-height: 40px;
      color: #666;
    }
  }
}
</style>